.container {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-evenly;
  flex-wrap: wrap;
  color: var(--light-text);
  @media (max-width: 768px) {
    justify-content: space-around;
  }

  .title {
    font-size: var(--font-size-md);
    color: var(--light-text);
    margin: 0 0 8px 0;
  }
}

.footerColumn {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 1px;
  max-width: 192px;

  @media (max-width: 768px) {
    min-width: 46%;
    margin-bottom: var(--spacing-md);
    text-align: center;
  }
  @media (max-width: 370px) {
    min-width: unset;
    max-width: 100%;
    width: 100%;
  }
  .title {
    width: 100%;
  }
  .textContainer {
    text-align: left;
    max-width: 280px;

    @media (max-width: 768px) {
      text-align: center;
    }
  }
  .linkList {
    width: 100%;
    padding: 0;
    margin-top: 0;
    list-style: none;

    .item {
      margin: var(--spacing-xs) 0;
      &:first-of-type {
        margin-top: 0;
      }

      .link {
        color: var(--light-text);
        line-height: 150%;
        opacity: 0.8;
      }
    }
  }
}
